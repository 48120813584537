html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 10px;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

body ::selection {
    background: #b0d6fd;
    color: unset;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div, input, textarea {
	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

a { text-decoration: none; color: #0070c9; cursor: pointer; }
a:hover { text-decoration: none; }
a:focus { outline: none; }
a:-webkit-any-link { text-decoration: none; }

button:focus { outline: 0 !important; }

/* disable double-tap to zoom on iOS */
html, body {
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
}

.marquee {
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
}

.marquee span {
    display: inline-block;
    padding-left: 100%;
    will-change: transform;
    animation: marquee 24s linear infinite;
}

.marquee span:hover {
    animation-play-state: paused
}

@keyframes marquee {
    0% { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}