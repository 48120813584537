.albumlist {
    display: block;
    width: 100%;
    background-color: #fff;
}

.albumlist ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.albumlist-item {
    display: block;
    padding: 16px;
    font-size: 1.4rem;
    color: #666;
    border-bottom: 1px solid #ccc;
    user-select: none;
    cursor: pointer;
}
.albumlist-item:first-child {
    border-top: 1px solid #ccc;
}

.albumlist-item:hover {
    background-color: #ebf1fb;
}

.albumlist-item:active {
    background-color: #cfe8fc;
}

.albumlist-item .title {
    font-weight: bold;
    text-align: center;
}

.albumlist .label-icon {
    display: block;
    width: 100%;
    text-align: center;
}
.albumlist .label-icon img {
    display: inline-block;
    width: 320px;
    height: auto;
    border: none;
}

.albumlist .label-desc {
    display: block;
    width: 320px;
    margin: 16px auto;
    white-space: pre-wrap;
}
.albumlist .label-desc.collapse {
    display: none;
}

.albumlist .button-more {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    background: none;
    border: none;
    font-weight: normal;
    text-align: center;
    padding: 4px;
    color: #333;

    display: block;  
    width: 320px;
    margin: 0 auto;
    text-align: right;
    cursor: pointer;
}
.albumlist .button-more:hover {
    color: #000;
}
.albumlist .button-more:active {
    color: #0070c9;
}

.albumlist .button-more span {
    display: inline-block; 
    vertical-align: middle;
    font-size: 1.4rem;
    line-height: 100%;
}

.albumlist .button-more .icon-caret-right,
.albumlist .button-more .icon-caret-down {
    display: inline-block;
    vertical-align: middle;
    font-size: 2rem;
    line-height: 100%;
}