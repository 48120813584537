.app {
    display: block;
    width: 100%;
    height: 100%;
    background: #eee;
}

.player {
    position: relative;
    max-width: 768px;
    margin: 0 auto;
    background-color: #fff;
}
/* --- controls --- */

.controls {
    display: block;
}
.controls.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 768px;
    z-index: 1;
    background-color: rgba(245, 245, 245, 0.8);
}

.controls button {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    background: none;
    border: none;
    font-weight: normal;
    text-align: center;
    padding: 0px 4px 6px 4px;
    color: #333;
}
.controls button:hover {
    color: #000;
}
.controls button:active {
    color: #0070c9;
}

.controls .button-play,
.controls .button-pause {
    display: inline-block;    
    vertical-align: middle;
    font-size: 3.6rem;
    margin: 0 8px;
    cursor: pointer;
}

.controls .button-prev,
.controls .button-next {
    display: inline-block;
    vertical-align: middle;
    font-size: 2.6rem;
    cursor: pointer;
}

.controls .icon-play,
.controls .icon-pause,
.controls .icon-prev,
.controls .icon-next {
    vertical-align: middle;
}

.controls .playback-buttons {
    display: block;
    text-align: center;
    padding: 16px;
}

.controls .playback-position {
    display: block;
}

.controls .playback-position .slider {
    display: block;
    width: 90%;
    margin: 0 auto;
}

.controls .playback-position .label-position {
    display: block;
    padding: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.4rem;
    text-align: center;
}

/* --- visualizer --- */

.visualizer {
    display: block;
    width: 100%;
    height: 256px;
    position: relative;
    overflow: hidden;
}

.visualizer canvas {
    display: block;
    position: relative;
    margin: 0 auto;
}

.visualizer .cover {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 25%;
}
.visualizer .cover.blur {
    filter: blur(4px);
}

.visualizer .cover::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color:rgba(255, 255, 255, 0.65);
}

.visualizer .title {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    font-size: 1.4rem;
    color: #fff;
    background-color: rgba(128, 128, 128, 0.25);
}
.visualizer .title p {
    margin: 0;
    padding: 0;
}

/* --- playlist --- */

.playlist {
    display: block;
    width: 100%;
}

.playlist ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.playlist-item {
    display: block;
    padding: 16px;
    font-size: 1.4rem;
    color: #666;
    border-bottom: 1px solid #ccc;
    user-select: none;
    cursor: pointer;
}
.playlist-item:first-child {
    border-top: 1px solid #ccc;
}

.playlist-item:hover {
    background-color: #eee;
}

.playlist-item:active {
    background-color: #ccc;
}

.playlist-item.selected {
    background-color: #333;
    color: #fff;
    font-weight: bold;
}

.playlist .label-title {
    display: inline-block;
    width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.playlist .label-artist {
    display: inline-block;
    width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
}

/* --- lyric --- */

.lyric {
    display: block;
    width: 100%;
    height: auto;
    line-height: 32px;
    text-align: center;
    word-wrap: break-word;
    pointer-events: none;
    position: absolute;
    top: 192px;
}

.lyric .x-content {
    font-size: 24px;
    color: #fff;
    text-shadow: 0 1px 3px #333;
}

.lyric .x-trans {
    color: #808080;
    font-size: 16px;
    text-shadow: 0 1px 2px #fff;
}