.slider {
    margin: 2px;
    position: relative;
    user-select: none;
}
.slider.horizontal {
    width: 100%;
}
.slider.vertical {
    height: 100%;
}

.slider .slider-track {
    border-radius: 2px;
    background: #ddd;
}
.slider.horizontal .slider-track {
    width: 100%;
    height: 4px;
    position: absolute;
    left: 0;
    top: -2px;
}
.slider.vertical .slider-track {
    width: 4px;
    height: 100%;
    position: absolute;
    left: 6px;
    top: 0;
}

.slider .slider-thumb {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 16px;
    height: 16px;    
    cursor: pointer; 
    /* transition: transform ease .1s; */
}
.slider.horizontal .slider-thumb {
    position: absolute;
    left: 0;
    top: -8px;
}
.slider.vertical .slider-thumb {
    position: absolute;
    left: 0px;
    top: 0;
}

.slider .slider-thumb span {
    border: 1px solid transparent;
    border-radius: 16px;
    width: 16px;
    height: 16px;
    position: absolute;
    left: -2px;
    top: -2px;
    box-shadow: 0 0 4px #b0d6fd;
    transition: all ease .25s;
}
.slider .slider-thumb:active span {
    border: 1px solid transparent;
    border-radius: 16px;
    width: 32px;
    height: 32px;
    position: absolute;
    left: -10px;
    top: -10px;
    box-shadow: 0 0 4px #b0d6fd;
}

/* unify range slider style */
input[type=range] {
    appearance: none;
    outline: none;
    background: none;
    background-color: #ddd;
    border: 0;
    border-radius: 2px;
    width: 100%;
    height: 4px;
}

input[type=range]::-webkit-slider-thumb {
    appearance: none;
    outline: none;
    background: none;
    background-color: #fff;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 16px;
    height: 16px;    
    cursor: pointer;
}

input[type=range]::-moz-range-thumb {
    appearance: none;
    outline: none;
    background: none;
    background-color: #fff;
    box-shadow: none;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 14px;
    height: 14px;    
    cursor: pointer;
}

input[type=range]::-moz-focus-outer {
    border: 0;
    outline: none;
}